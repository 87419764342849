#main-page {
    display: flex;
    width: 100%;
    justify-content: center;
}

@media (max-width: 1000px) {
    #main-page-card {
        width: 95%;
    }
}

@media (min-width: 1000px) {
    #main-page-card {
        width: 60%;
        max-width: 1200px;
    }
}