body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, textarea, p, blockquote, th, td {
    margin: 0;
    padding: 0;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

fieldset, img {
    border: 0;
}

address, caption, cite, code, dfn, th, var {
    font-style: normal;
    font-weight: normal;
}

caption, th {
    text-align: left;
}

q:before, q:after {
    content: '';
}

abbr, acronym {
    border: 0;
}

html {
    background-color: #f3f3f3;
    color: #161616;
}

.hero-content {
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}