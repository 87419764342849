#relationships-feedback {
    display: flex;
    width: 100%;
    justify-content: center;
}

@media (max-width: 1000px) {
    #relationships-feedback-card {
        width: 95%;
    }
}

@media (min-width: 1000px) {
    #relationships-feedback-card {
        width: 60%;
        max-width: 1200px;
    }
}

.big-checkbox {
    height: 20px;
}

.big-checkbox input[type="checkbox"] {
    height: 20px;
    width: 20px;
}

select.form-select.unselected {
    color: #6c757d;
}

.form-select option {
    color: #212529;
}

.form-select option.unselected {
    color: #6c757d;
}

.right-aligned {
    text-align: right;
    margin-right: 10px;
}

.socialShare svg {
    height: 30px
}